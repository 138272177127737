import { graphql, StaticQuery } from "gatsby";
import _ from "lodash";
import React from "react";
import "./cloud.scss";
import {
  getPage,
  TypeCloudHostingOptionsPage,
  TypeCloudHostingOptionsQueryResult,
  TypeCloudHostingRegion,
} from "../../utils/queries/cloud_hosting_services";
import {
  PageHeaderCustomV2,
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";
import RichTextRender from "../../utils/rich-text";
import { ResourceInteruption } from "../../templates/resources/customers";
import { QuoteCarousel } from "../../components/reusable/quote-carousel";
import { TypeHomepageV2Quote } from "../../utils/queries/homepagev2";
import Helmet from "react-helmet";
import {
  CustomerStandardCard,
  StandardCard,
} from "../../templates/resources/cards";
import { Resource } from "../../utils/queries/resources";

const query = graphql`
  {
    allPrismicCloudPageV2 {
      edges {
        node {
          data {
            body {
              ... on PrismicCloudPageV2BodyRegion {
                id
                primary {
                  region_name
                  continent
                }
                items {
                  location
                  host_logo {
                    url
                    alt
                  }
                }
              }
            }
            map_background {
              alt
              url
            }
            cta_button_text
            cta_text
            page_meta_description
            page_meta_thumbnail {
              url
              alt
            }
            hero_image {
              url
              alt
            }
            second_cta_button_text
            section_subtitle1
            section_title
            section_title1
            section_title2
            services {
              image {
                url
                alt
              }
              description
              title1
            }
            subtitle {
              text
              raw
              html
            }
            title
            meta_title
            cta_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                    is_external
                  }
                }
              }
            }
            second_cta_button_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    is_external
                    friendly_name
                  }
                }
              }
            }
            section_subtitle {
              text
              raw
              html
            }
            quotes {
              quote
              author_position
              author_name
              logo {
                url
                alt
              }
            }
            customer_stories {
              case_study {
                document {
                  ... on PrismicResource {
                    id
                    data {
                      company_name
                      title
                      resource_type {
                        document {
                          ... on PrismicResourceType {
                            id
                            data {
                              type
                              display_in_filter_dropdown
                            }
                          }
                        }
                      }
                      description {
                        html
                        raw
                        text
                      }
                      case_study_content {
                        document {
                          ... on PrismicCaseStudy {
                            id
                            data {
                              slug
                            }
                          }
                        }
                      }
                      link {
                        document {
                          ... on PrismicCaseStudy {
                            id
                            data {
                              slug
                            }
                          }
                        }
                        type
                        link_type
                        url
                      }
                      image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CloudHostingOptionsPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeCloudHostingOptionsQueryResult;
}) => {
  const result = usePreviewData(
    getPage(data),
    "allPrismicCloudPageV2",
    true
  ) as TypeCloudHostingOptionsPage;

  // Group regions by continent
  const continents = _.uniq(
    result.body.map((region, index) => region.primary.continent)
  ).map((continent) => ({
    continent: continent as TypeCloudHostingRegion["primary"]["continent"],
    regions: result.body.filter(
      (region) => region.primary.continent === continent
    ),
  }));

  const renderableResources = result.customer_stories.map(
    (rawResource) => new Resource(rawResource.case_study.document.data)
  );
  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="cloud-hosting-services">
        <PageTitle title={title} amazeeOnly={true} />
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={result.title}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="map">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-8 text-center">
                <h2 className="text-2xl font-semibold">
                  {RichTextRender(result.section_title)}
                </h2>
                <p>{RichTextRender(result.section_subtitle)}</p>
              </div>
              <div className="col-12">
                <div className="row places">
                  <div className="places-inner">
                    {continents.map((continent, index) => (
                      <div
                        className="flex-no-shrink flex-no-grow map-section"
                        key={index}
                      >
                        <div className="continent">{continent.continent}</div>
                        <div className="regions flex  flex-wrap">
                          {continent.regions.map((region, index) => (
                            <div
                              className="region w-1/2 flex-no-shrink flex-no-grow"
                              key={index}
                            >
                              <div className="region-name">
                                {region.primary.region_name}
                              </div>
                              {region.items.map((location, index) => (
                                <div key={index}>
                                  <div className="location">
                                    <img
                                      src={location.host_logo.url}
                                      alt={location.host_logo.alt}
                                      className="img"
                                    />
                                    {RichTextRender(location.location)}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <img
                  src={result.map_background.url}
                  alt={result.map_background.alt}
                  className="w-full"
                />
                <ul className="flex flex-wrap justify-center mt-8 mb-4 mx-6">
                  <li className="mr-4 mb-2">
                    Fastly Points of Presence (POPs)
                  </li>
                  <li className="mr-4 mb-2">
                    <span className="w-4 h-4 mr-2 inline-block bg-amazee-deep-sky-blue rounded-full"></span>
                    Single POP
                  </li>
                  <li className="mb-2">
                    <span className="w-4 h-4 mr-2 inline-block bg-amazee-deep-sky-green rounded-full"></span>
                    Multiple POPs
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="services">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2>{RichTextRender(result.section_title1)}</h2>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <p className="font-normal text-lg">
                  {RichTextRender(result.section_subtitle1)}
                </p>
              </div>
            </div>
            <div className="row flex justify-center">
              <div className="col-12 col-lg-9">
                <div className="row">
                  {result.services.map((service, index) => (
                    <div key={index} className="col-12 col-lg-6 service">
                      <img src={service.image.url} alt={service.image.alt} />
                      <h3 className="service-title">{service.title1}</h3>
                      <p className="description">{service.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <ResourceInteruption
          cta_text={result.cta_text}
          cta_button_text={result.cta_button_text}
          second_cta_button_text={result.second_cta_button_text}
          cta_link={result.cta_link}
          second_cta_button_link={result.second_cta_button_link}
        />

        <section id="quotes">
          <div className="container">
            <div className="row">
              <div className="col-12 quote-carousel-section">
                <QuoteCarousel
                  withComma={true}
                  quotes={result.quotes.map(
                    (quote) =>
                      ({
                        image: quote.logo,
                        text: quote.quote,
                        author_name: quote.author_name,
                        author_title: quote.author_position,
                      } as TypeHomepageV2Quote)
                  )}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="customers">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h3>{RichTextRender(result.section_title2)}</h3>
              </div>
            </div>

            <div className="row">
              {renderableResources.map((resource, index) =>
                resource.companyName ? (
                  <CustomerStandardCard resource={resource} key={index} />
                ) : (
                  <StandardCard resource={resource} key={index} />
                )
              )}
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticDedicatedHostingOptionsPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <CloudHostingOptionsPage location={location} data={data} />
      )}
    />
  );
};
export default StaticDedicatedHostingOptionsPage;
