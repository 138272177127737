import { PrismicImage, PrismicPageDefinition, PrismicRichText } from "./types";
import {
  PrismicRawResourceData,
  PrismicRawResourceEdge,
  Resource,
} from "./resources";
import { TypeHomepageV2Quote } from "./homepagev2";

export type CloudHostingContinent = "North America" | "Europe" | "APAC";
export type TypeCloudHostingRegion = {
  id: string;
  primary: {
    region_name: string;
    continent: CloudHostingContinent;
  };
  items: Array<{
    location: string;
    host_logo: PrismicImage;
  }>;
};

export type TypeCloudHostingService = {
  image: PrismicImage;
  title1: string;
  description: string;
};

export type TypeCloudHostingOptionsPage = {
  title: string;
  meta_title: string;
  subtitle: PrismicRichText;
  body: Array<TypeCloudHostingRegion>;

  hero_image: PrismicImage;
  section_title: string;
  section_subtitle: PrismicRichText;
  section_title1: string;
  section_subtitle1: PrismicRichText;
  section_title2: string;

  cta_text: string;
  cta_button_text: string;
  cta_link: PrismicPageDefinition;
  second_cta_button_text: string;
  second_cta_button_link: PrismicPageDefinition;

  services: TypeCloudHostingService[];
  quotes: Array<{
    author_name: string;
    author_position: string;
    quote: string;
    logo: PrismicImage;
  }>;
  map_background: PrismicImage;
  customer_stories: Array<{
    case_study: {
      document: {
        data: PrismicRawResourceData;
      };
    };
  }>;

  page_meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;
};

export type TypeCloudHostingOptionsQueryResult = {
  allPrismicCloudPageV2: {
    edges: Array<{
      node: { data: TypeCloudHostingOptionsPage };
    }>;
  };
};

export const getPage = (
  data: TypeCloudHostingOptionsQueryResult
): TypeCloudHostingOptionsPage => {
  return data.allPrismicCloudPageV2.edges[0].node.data;
};
